import React from 'react';
import { Switch, Route } from 'react-router-dom';

import Login from './pages/Login';
import Register from './pages/Register';
import Home from './pages/Home';

function Routes() {
  return (
    <Switch>
      <Route path='/' exact component={Login} />
      <Route path='/register' exact component={Register} />
      <Route path='/home' exact component={Home} />
    </Switch>
  );
}

export default Routes;