import { useEffect, useState } from 'react';
import api from '../../services/api';

import { Box, Button, Card, CardContent, CardHeader, Divider, useTheme } from '@mui/material';
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
import ArrowRightIcon from '@mui/icons-material/ArrowRight';
import 'chart.js/auto';

import { Chart } from 'react-chartjs-2';
import faker from 'faker';

export default function App({ profile, props, mobileView }) {

  useEffect(() => {

    getSecretaries();

  }, []);

  let [secretaries, setSecretaries] = useState([]);
  let [secretaryProducts, setSecretaryProducts] = useState([]);
  let [typeLength, setTypeLength] = useState([]);
  let [typeLength2, setTypeLength2] = useState([]);
  let [materialSpending, setMaterialSpending] = useState([]);
  let [serviceSpending, setServiceSpending] = useState([]);
  let [totalValues, setTotalValues] = useState([]);
  let [avaliable, setAvaliable] = useState([]);
  let [totalSpending, setTotalSpending] = useState([]);
  let [estimatedValue, setEstimatedValue] = useState([]);

  async function getSecretaries() {

    try {
      let resp = await api.get('/secretarieswithproducts', {
        params: {
          place: profile.place,
        }
      });
      if (resp != null) {
        if (resp.data) {
          if (JSON.stringify(resp.data).includes('name')) {

            if (secretaries.length > 0) {
              while (secretaries.length > 0) {
                secretaries.pop();
              }
            }

            for (let i = 0; i < resp.data.length; i++) {

              let aux = {
                id: resp.data[i].id,
                name: resp.data[i].name,
                place_name: resp.data[i].place_name,
                value: resp.data[i].value,
              };
              secretaries = Object.assign([], secretaries);
              secretaries.push(aux);
            }

            await setSecretaries(secretaries);

            getProductsPerSecretary();

          } else {
            //alert('Nenhuma secretaria encontrada. ' + resp.data);
          }
        }
      }
    } catch (error) {
      alert(error);
    }

  }

  async function getProductsPerSecretary() {

    try {
      let resp = await api.get('/productspersecretary', {
        params: {
          place: profile.place,
        }
      });
      if (resp != null) {
        if (resp.data) {
          if (JSON.stringify(resp.data).includes('name')) {

            if (secretaryProducts.length > 0) {
              while (secretaryProducts.length > 0) {
                secretaryProducts.pop();
              }
            }

            if (typeLength.length > 0) {
              while (typeLength.length > 0) {
                typeLength.pop();
              }
            }

            if (typeLength2.length > 0) {
              while (typeLength2.length > 0) {
                typeLength2.pop();
              }
            }

            if (materialSpending.length > 0) {
              while (materialSpending.length > 0) {
                materialSpending.pop();
              }
            }

            if (serviceSpending.length > 0) {
              while (serviceSpending.length > 0) {
                serviceSpending.pop();
              }
            }

            if (avaliable.length > 0) {
              while (avaliable.length > 0) {
                avaliable.pop();
              }
            }

            if (totalSpending.length > 0) {
              while (totalSpending.length > 0) {
                totalSpending.pop();
              }
            }

            if (estimatedValue.length > 0) {
              while (estimatedValue.length > 0) {
                estimatedValue.pop();
              }
            }

            for (let i = 0; i < resp.data.length; i++) {

              let aux = {
                id: resp.data[i].id,
                name: resp.data[i].name,
                count: resp.data[i].count,
                item_type: resp.data[i].item_type,
                product_value: resp.data[i].product_value,
              };
              secretaryProducts = Object.assign([], secretaryProducts);
              secretaryProducts.push(aux);
            }

            await setSecretaryProducts(secretaryProducts);

            for (let i = 0; i < secretaries.length; i++) {
              typeLength.push(0);
              typeLength2.push(0);
              materialSpending.push(0);
              serviceSpending.push(0);
              avaliable.push(0);
              //estimatedValue.push(0);
            }

            for (let i = 0; i < secretaryProducts.length; i++) {
              for (let j = 0; j < secretaries.length; j++) {
                if (secretaryProducts[i].name === secretaries[j].name) {
                  if (secretaryProducts[i].item_type === 'material') {
                    typeLength[j] = typeLength[j] + secretaryProducts[i].count;
                    materialSpending[j] = parseFloat(materialSpending[j]) + parseFloat(secretaryProducts[i].product_value);
                  } else {
                    typeLength2[j] = typeLength2[j] + secretaryProducts[i].count;
                    serviceSpending[j] = parseFloat(serviceSpending[j]) + parseFloat(secretaryProducts[i].product_value);
                  }
                }
              }
            }

            setTypeLength(typeLength);
            setTypeLength2(typeLength2);
            setMaterialSpending(materialSpending);
            setServiceSpending(serviceSpending);
            await setTotalSpending(totalSpending);

            for (let i = 0; i < materialSpending.length; i++) {
              totalSpending.push(parseFloat(materialSpending[i]) + parseFloat(serviceSpending[i]));
              //avaliable.push(secretaries[i].value - totalSpending[i]);
              estimatedValue.push(parseFloat(totalSpending[i]) - (parseFloat(totalSpending[i] * 2)));
            }
            await setTotalSpending(totalSpending);
            await setEstimatedValue(estimatedValue);

            getTotalValues();

          } else {
            //alert('Nenhuma quantidade de produtos encontrada. ' + resp.data);
          }
        }
      }
    } catch (error) {
      alert(error);
    }

  }

  async function getTotalValues() {

    try {
      let resp = await api.get('/totalpersecretary', {
        params: {
          place: profile.place,
        }
      });
      if (resp != null) {
        if (resp.data) {
          if (JSON.stringify(resp.data).includes('name')) {

            if (totalValues.length > 0) {
              while (totalValues.length > 0) {
                totalValues.pop();
              }
            }

            for (let i = 0; i < resp.data.length; i++) {

              let aux = {
                id: resp.data[i].id,
                name: resp.data[i].name,
                tree_value: resp.data[i].tree_value,
              };
              totalValues = Object.assign([], totalValues);
              totalValues.push(aux);
            }

            await setTotalValues(totalValues);

            for (let i = 0; i < secretaries.length; i++) {
              for (let j = 0; j < totalValues.length; j++) {
                if (secretaries[i].name === totalValues[j].name) {
                  avaliable[i] = totalValues[j].tree_value - totalSpending[j];
                }
              }
            }

            await setAvaliable(avaliable);

          } else {
            alert('Nenhum valor encontrado. ' + resp.data);
          }
        }
      }
    } catch (error) {
      alert(error);
    }

  }
  
  const labels = secretaries.map((secretary) => secretary.name);

  const data = {
    labels,
    datasets: [
      {
        type: 'line',
        label: 'Ranking de gastos',
        borderColor: 'rgb(102,51,255)',
        borderWidth: 2,
        fill: false,
        data: totalSpending,
      },
      {
        type: 'bar',
        label: 'Gasto estimado',
        backgroundColor: 'rgba(204, 0, 0)',
        data: estimatedValue,
        borderColor: 'white',
        borderWidth: 2,
        barThickness: 25,
        borderRadius: 4,
      },
      {
        type: 'bar',
        label: 'Valor disponível',
        backgroundColor: 'rgba(50, 205, 50)',
        data: avaliable,
        barThickness: 25,
        borderRadius: 4,
      },
    ],
  };

  return (
    <Card {...props} sx={{ backgroundColor: '#fff', marginRight: 1, width: mobileView ? '100%' : '70%' }}>
      <CardHeader
        action={(
          <Button
            endIcon={<ArrowDropDownIcon fontSize="small" />}
            size="small"
          >
            Últimos anos
          </Button>
        )}
        sx={{ color: '#252525' }}
        title="Ranking de gastos por secretaria"
      />
      <Divider />
      <CardContent>
        <Box
          sx={{
            width: '100%',
            position: 'relative',
          }}
        >
          <Chart style={{ height: 700 }} type='bar' data={data} />
        </Box>
      </CardContent>
      <Divider />
      <Box
        sx={{
          display: 'flex',
          justifyContent: 'flex-end',
          p: 2,
        }}
      >
        <Button
          color="primary"
          endIcon={<ArrowRightIcon fontSize="small" />}
          size="small"
        >
          Visão geral
        </Button>
      </Box>
    </Card>
  );
}
