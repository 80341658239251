import React from 'react';
import { Line } from 'react-chartjs-2';
import faker from 'faker';
import { Box } from '@mui/material';

const App = (props) => {

  const { dates/*, objectsLength*/ } = props;

  const options = {
    responsive: true,
    plugins: {
      legend: {
        position: 'bottom',
      },
      title: {
        display: true,
        text: 'Movimentação temporal dos produtos',
      },
    },
  };
  
  //const labels = dates;
  const labels = ['Janeiro', 'Fevereiro', 'Março', 'Abril', 'Maio', 'Junho', 'Julho', 'Agosto'];
  const objectsLength = [3, 4, 8, 5, 7, 2, 1];
  
  const data = {
    labels,
    datasets: [
      {
        label: 'Pendentes',
        data: labels.map(() => faker.datatype.number({ min: 0, max: 100 })),
        borderColor: 'rgb(251, 140, 0)',
        backgroundColor: 'rgba(255, 217, 102, 1)',
      },
      {
        label: 'Aprovados',
        data: labels.map(() => faker.datatype.number({ min: 0, max: 100 })),
        borderColor: 'rgb(0, 255, 39)',
        backgroundColor: 'rgba(0, 255, 39, 0.5)',
      },
      {
        label: 'Negados',
        data: labels.map(() => faker.datatype.number({ min: 0, max: 100 })), //objectsLength
        borderColor: 'rgb(255, 0, 39)',
        backgroundColor: 'rgba(255, 0, 39, 0.5)',
      },
    ],
  };

  return (
    <Box
      {...props}
      sx={{
        width: '60%',
        margin: 0,
        marginTop: 0,
        marginBottom: 2,
        position: 'relative',
        backgroundColor: '#fff',
        borderRadius: 1,
        borderWidth: 5,
        borderColor: '#323232',
      }}
    >
      <Line options={options} data={data} />
    </Box>
  );
}

export default App;