import React from 'react';
import { BrowserRouter } from 'react-router-dom';

import theme from './components/theme';
import Routes from './routes';

import { ThemeProvider } from '@mui/material/styles';
//import { ThemeProvider } from '@material-ui/styles';

function App() {
  return (
    <BrowserRouter>

      <head>
        <meta name="viewport" content="initial-scale=1, width=device-width" />
      </head>
    
      <link
        rel="stylesheet"
        href="https://fonts.googleapis.com/css?family=Roboto:300,400,500,700&display=swap"
      />
      <link
        rel="stylesheet"
        href="https://fonts.googleapis.com/icon?family=Material+Icons"
      />

      <ThemeProvider theme={theme}>
        <Routes />
      </ThemeProvider>

    </BrowserRouter>
  );
}

export default App;
