import * as React from 'react';
import api from '../../services/api';
import apiStringServer from '../../services/apiStringServer';

import {
  Avatar, Box, Card, CardContent, Typography, IconButton, Menu, MenuItem, Dialog, DialogTitle, DialogContent, DialogContentText, 
  DialogActions, Button, Divider, Container, TextField, List, ListItem, ListItemText, ListItemAvatar
} from '@mui/material';

import { DataGrid, ptBR } from '@mui/x-data-grid';

import { Settings, AccountCircle, PowerSettingsNew, Visibility, Edit, Delete, Person, HomeWork, Refresh, ListAlt } from '@mui/icons-material';

import moment from 'moment';

import styles from '../styles/styles';

const user = {
  id: '',
  name: '',
  email: '',
  cpf_cnpj: '',
  type: '',
  place: '',
  photo_url: '',
  phone: '',
  cep: '',
  street: '',
  number: '',
  complement: '',
  district: '',
  city: '',
  state: '',
  secretary_id: '',
};

function AccountProfile({ profile, props }) {

  React.useEffect(() => {

    getUsers();
    getSecretaries();
    getLogs();

  }, []);

  const columns = [
    { field: 'id', headerName: 'ID', width: 90 },
    {
      field: 'name',
      headerName: 'Nome',
      width: 250,
      editable: false,
      renderHeader: () => (
        <strong>
          {'Nome '}
          <span role="img" aria-label="enjoy">
            🔤
          </span>
        </strong>
      ),
    },
    {
      field: 'email',
      headerName: 'E-mail',
      width: 250,
      editable: false,
      renderHeader: () => (
        <strong>
          {'E-mail '}
          <span role="img" aria-label="enjoy">
            📧
          </span>
        </strong>
      ),
    },
    {
      field: 'cpf_cnpj',
      headerName: 'Documento',
      width: 250,
      editable: false,
      renderHeader: () => (
        <strong>
          {'Documento '}
          <span role="img" aria-label="enjoy">
            📄
          </span>
        </strong>
      ),
    },
    {
      field: 'type_name',
      headerName: 'Tipo',
      width: 175,
      editable: false,
      renderHeader: () => (
        <strong>
          {'Tipo '}
          <span role="img" aria-label="enjoy">
            👤
          </span>
        </strong>
      ),
    },
    {
      field: 'actions',
      headerName: 'Ações',
      renderCell: () => (
        <Actions />
      ),
      sortable: false,
      width: 100,
      headerAlign: 'center',
      filterable: false,
      align: 'center',
      disableColumnMenu: true,
      disableReorder: true,
    },
  ];
  const columns2 = [
    { field: 'id', headerName: 'ID', width: 90 },
    {
      field: 'name',
      headerName: 'Nome',
      width: 350,
      editable: false,
      renderHeader: () => (
        <strong>
          {'Nome '}
          <span role="img" aria-label="enjoy">
            🔤
          </span>
        </strong>
      ),
    },
    {
      field: 'place_name',
      headerName: 'Local',
      width: 250,
      editable: false,
      renderHeader: () => (
        <strong>
          {'Local '}
          <span role="img" aria-label="enjoy">
            🗺️
          </span>
        </strong>
      ),
    },
    {
      field: 'value',
      headerName: 'Valor estimado',
      width: 150,
      editable: false,
      renderHeader: () => (
        <strong>
          {'Valor estimado '}
          <span role="img" aria-label="enjoy">
            💲
          </span>
        </strong>
      ),
    },
    {
      field: 'actions',
      headerName: 'Ações',
      renderCell: () => (
        <Actions2 />
      ),
      sortable: false,
      width: 100,
      headerAlign: 'center',
      filterable: false,
      align: 'center',
      disableColumnMenu: true,
      disableReorder: true,
    },
  ];

  let rows = [];
  let rows2 = [];

  let rowsArray = [];
  let rowsArray2 = [];

  const [page_rows, setPageRows] = React.useState([]);
  const [page_rows2, setPageRows2] = React.useState([]);

  function Actions({ props }) {

    const [openDialog, setOpenDialog] = React.useState(false);
    const [userData, setUserData] = React.useState([]);
  
    async function handleSee() {
  
      for (let i = 0; i < userData.length; i++) {
        userData.pop();
      }

      try {
        let resp = await api.get('/user', {
          params: {
            user_id: user_id,
          }
        });
        if (resp != null) {
          if (resp.data) {
            if (JSON.stringify(resp.data).includes('name')) {
              await setUserData([]);
              userData.push(resp.data);
              await setUserData(userData);
              //alert(JSON.stringify(userData));
              setOpenDialog(true);
            } 
          }
        }
      } catch (error) {
        setOpenDialog(false);
        alert(error);
      }
  
    }
  
    return(
      <Box>
        <IconButton
          color="inherit"
          size="small"
          aria-label="see"
          onClick={() => { handleSee(); }}
        >
          <Visibility fontSize="small" />
        </IconButton>
        <IconButton
          color="inherit"
          size="small"
          aria-label="edit"
        >
          <Edit fontSize="small" />
        </IconButton>
        <IconButton
          color="inherit"
          size="small"
          aria-label="delete"
        >
          <Delete fontSize="small" />
        </IconButton>

        <Dialog open={openDialog} onClose={() => setOpenDialog(false)}
          aria-labelledby="form-dialog-title"
        >
          <DialogTitle id="form-dialog-title">Usuário</DialogTitle>
          <DialogContent>
            <DialogContentText color="#000">
              Detalhes do usuário:
            </DialogContentText>

            {userData.map((item) =>
              <Container>
                <Avatar
                  src={`${apiStringServer}/images/${item.photo_url}`}
                  sx={{ width: 200, height: 200, borderRadius: 0, borderWidth: 1, borderColor: '#000' }}
                />
                <Typography variant="h6" color="text.primary">
                  {item.name}
                </Typography>
                <Typography variant="body1" color="text.secondary">
                  {item.email}
                </Typography>
                <Typography variant="body1" color="text.secondary">
                  {item.cpf_cnpj}
                </Typography>
                <Typography variant="body1" color="text.secondary">
                  {item.type_name}
                </Typography>
              </Container>
            )}

          </DialogContent>
          <DialogActions>
            <Button onClick={() => setOpenDialog(false)}>
              Fechar
            </Button>
          </DialogActions>
        </Dialog>
      </Box>
    );
  }

  function Actions2({ props }) {

    const [openDialog, setOpenDialog] = React.useState(false);
    const [value, setValue] = React.useState('');
    
    async function handleEdit() {

      try {
        let resp = await api.get('/updatesecretary', {
          params: {
            secretary_id: secretary_id,
            value: value,
          }
        });
        if (resp != null) {
          if (resp.data) {
            if (JSON.stringify(resp.data).includes('updated')) {
              getSecretaries();
              setOpenDialog(false);
            } 
          }
        }
      } catch (error) {
        setOpenDialog(false);
        alert(error);
      }
  
    }
  
    return(
      <Box>
        <IconButton
          color="inherit"
          size="small"
          aria-label="edit"
          onClick={() => { setOpenDialog(true); }}
        >
          <Edit fontSize="small" />
        </IconButton>
        <IconButton
          color="inherit"
          size="small"
          aria-label="delete"
        >
          <Delete fontSize="small" />
        </IconButton>

        <Dialog open={openDialog} onClose={() => setOpenDialog(false)}
          aria-labelledby="form-dialog-title"
        >
          <DialogTitle id="form-dialog-title">{secretaryName}</DialogTitle>
          <DialogContent>
            <DialogContentText color="#000">
              Alteração de valor estimado:
            </DialogContentText>

            <TextField
              margin="dense"
              type="number"
              id="value"
              label="Valor estimado"
              fullWidth
              value={value}
              onChange={(e) => setValue(e.target.value)}
            />

          </DialogContent>
          <Box sx={styles.directionRow}>
            <DialogActions>
              <Button onClick={() => setOpenDialog(false)}>
                Fechar
              </Button>
            </DialogActions>
            <DialogActions>
              <Button onClick={() => handleEdit()}>
                Alterar
              </Button>
            </DialogActions>
          </Box>
        </Dialog>
      </Box>
    );
  }

  const [anchorEl, setAnchorEl] = React.useState(null);
  const [users, setUsers] = React.useState([]);
  const [user_id, setUserId] = React.useState('');
  const [secretaries, setSecretaries] = React.useState([]);
  const [secretary_id, setSecretary_id] = React.useState('');
  const [secretaryName, setSecretaryName] = React.useState('');
  const [logs, setLogs] = React.useState([]);
  const [grid_type, setGridType] = React.useState(1);

  user.photo_url = `${apiStringServer}/images/${profile.photo_url}`;

  user.id = profile.id;
  user.name = profile.name;
  user.email = profile.email;
  user.cpf_cnpj = profile.cpf_cnpj;
  user.type = profile.type;
  user.place = profile.place;
  user.phone = profile.phone;
  user.cep = profile.cep;
  user.street = profile.street;
  user.number = profile.number;
  user.complement = profile.complement;
  user.district = profile.district;
  user.city = profile.city;
  user.state = profile.state;
  user.secretary_id = profile.secretary_id;

  async function getUsers() {

    try {
      let resp = await api.get('/usersfromplace', {
        params: {
          place: user.place,
        }
      });
      if (resp != null) {
        if (resp.data) {
          if (JSON.stringify(resp.data).includes('name')) {
            await setUsers([]);
            users.push(resp.data);
            await setUsers(users);

            for (let i = 0; i < rows.length; i++) {
              rows.pop();
            }

            await setPageRows(rows);

            for (let i = 0; i < resp.data.length; i++) {

              let aux = {
                id: resp.data[i].id,
                name: resp.data[i].name,
                email: resp.data[i].email,
                cpf_cnpj: resp.data[i].cpf_cnpj,
                type_name: resp.data[i].type_name,
              };
              rows = Object.assign([], rows);
              rows.push(aux);
            }

            setPageRows(rows);

          } else {
            //alert('Nenhum usuário encontrado.');
          }
        }
      }
    } catch (error) {
      alert(error);
    }

  }

  async function getSecretaries() {

    try {
      let resp = await api.get('/secretaries', {
        params: {
          place: user.place,
        }
      });
      if (resp != null) {
        if (resp.data) {
          if (JSON.stringify(resp.data).includes('name')) {
            await setSecretaries([]);
            secretaries.push(resp.data);
            await setSecretaries(secretaries);

            for (let i = 0; i < rows2.length; i++) {
              rows2.pop();
            }

            await setPageRows2(rows2);

            for (let i = 0; i < resp.data.length; i++) {

              let aux = {
                id: resp.data[i].id,
                name: resp.data[i].name,
                place_name: resp.data[i].place_name,
                value: resp.data[i].value,
              };
              rows2 = Object.assign([], rows2);
              rows2.push(aux);
            }

            setPageRows2(rows2);

          } else {
            //alert('Nenhuma secretaria encontrada.');
          }
        }
      }
    } catch (error) {
      alert(error);
    }

  }

  async function getLogs() {

    try {
      let resp = await api.get('/logs', {
        params: {
          place: user.place,
        }
      });
      if (resp != null) {
        if (resp.data) {
          if (JSON.stringify(resp.data).includes('name')) {
            
            await setLogs(resp.data);

          } else {
            //alert('Nenhum log encontrado. ' + resp.data);
          }
        }
      }
    } catch (error) {
      alert(error);
    }

  }

  const handleMenu = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleExit = async () => {
    window.open('/', '_self');
  }

  return (
    <Card {...props}>
      <CardContent>
        <Box
          sx={{
            alignItems: 'center',
            display: 'flex',
            flexDirection: 'column'
          }}
        >
          <Avatar
            src={user.photo_url}
            sx={{
              height: 64,
              mb: 2,
              width: 64
            }}
          />
          <Typography
            color="textPrimary"
            gutterBottom
            variant="h5"
          >
            {user.name}
          </Typography>
          <Typography
            color="textSecondary"
            variant="body2"
          >
            {`${user.email}`}
          </Typography>
          <Typography
            color="textSecondary"
            variant="body2"
          >
            {user.type === 1 ? 'Administrador(a)' : user.type === 2 ? 'Órgão' : user.type === 3 ? 'Secretaria de Planejamento' : 
            user.type === 4 ? 'Analista' : 'Servidor'}
          </Typography>
        </Box>
      </CardContent>
      <Box
        sx={{
          alignItems: 'flex-end',
          display: 'flex',
          flexDirection: 'column',
          marginRight: 1
        }}
      >
        <IconButton aria-label="settings" onClick={handleMenu} >
          <Settings />
        </IconButton>
        <Menu
          id="menu-appbar"
          anchorEl={anchorEl}
          anchorOrigin={{
            vertical: 'top',
            horizontal: 'right',
          }}
          keepMounted
          transformOrigin={{
            vertical: 'top',
            horizontal: 'right',
          }}
          open={Boolean(anchorEl)}
          onClose={handleClose}
        >
          <MenuItem onClick={handleClose}><AccountCircle />Minha conta</MenuItem>
          {user.type === 3 ? (
            <Box>
              <MenuItem onClick={() => { setGridType(1); handleClose(); }}><Person />Usuários</MenuItem>
              <MenuItem onClick={() => { setGridType(2); handleClose(); }}><HomeWork />Secretarias</MenuItem>
              <MenuItem onClick={() => { setGridType(3); handleClose(); }}><ListAlt />Logs de usuários</MenuItem>
            </Box>
          ) : null}
          <MenuItem onClick={() => { handleClose(); handleExit(); }}><PowerSettingsNew />Sair</MenuItem>
        </Menu>
      </Box>
      <Divider />
      <CardContent>

        <Container>

          {user.type === 3 ? (
            <Box>
              {grid_type === 1 ? (
                <Box>
                  <Box sx={styles.directionRow}>
                      <Typography color="textSecondary" variant="body1">
                        Tabela de usuários
                      </Typography>
                      <IconButton
                        color="primary"
                        size="small"
                        aria-label="refresh"
                        onClick={() => { getUsers(); }}
                        sx={{ marginTop: -0.5 }}
                      >
                        <Refresh fontSize="small" />
                      </IconButton>
                    </Box>
                  <DataGrid
                    style={{ height: 500, width: '100%' }}
                    rows={page_rows}
                    columns={columns}
                    pageSize={7}
                    localeText={ptBR.components.MuiDataGrid.defaultProps.localeText}
                    rowsPerPageOptions={[7]}
                    disableSelectionOnClick
                    onCellClick={async (row) => {
                      await rowsArray.push(row);
                      await setUserId(rowsArray[0].row.id);
                      rowsArray.pop();
                    }}
                  />
                </Box>
              ) : grid_type === 2 ? (
                <Box>
                    <Box sx={styles.directionRow}>
                      <Typography color="textSecondary" variant="body1">
                        Tabela de secretarias
                      </Typography>
                      <IconButton
                        color="primary"
                        size="small"
                        aria-label="refresh"
                        onClick={() => { getSecretaries(); }}
                        sx={{ marginTop: -0.5 }}
                      >
                        <Refresh fontSize="small" />
                      </IconButton>
                    </Box>
                  <DataGrid
                    style={{ height: 500, width: '100%' }}
                    rows={page_rows2}
                    columns={columns2}
                    pageSize={7}
                    localeText={ptBR.components.MuiDataGrid.defaultProps.localeText}
                    rowsPerPageOptions={[7]}
                    disableSelectionOnClick
                    onCellClick={async (row) => {
                      await rowsArray2.push(row);
                      await setSecretary_id(rowsArray2[0].row.id);
                      await setSecretaryName(rowsArray2[0].row.name);
                      rowsArray2.pop();
                    }}
                  />
                </Box>
              ) : grid_type === 3 ? (
                <Box>
                  <Box sx={styles.directionRow}>
                      <Typography color="textSecondary" variant="body1">
                        Logs de Usuários
                      </Typography>
                      <IconButton
                        color="primary"
                        size="small"
                        aria-label="refresh"
                        onClick={() => { getLogs(); }}
                        sx={{ marginTop: -0.5 }}
                      >
                        <Refresh fontSize="small" />
                      </IconButton>
                    </Box>

                      <List sx={{ width: '100%', maxWidth: '100%', bgcolor: 'background.paper' }}>
                        {logs.map((item) =>
                          <Box>
                            <ListItem alignItems="flex-start">
                              <ListItemAvatar>
                                <Avatar alt={item.name} src={`${apiStringServer}/images/${item.photo_url}`} />
                              </ListItemAvatar>
                              <ListItemText
                                primary={`${item.name} (${item.email})`}
                                secondary={
                                  <React.Fragment>
                                    <Typography
                                      sx={{ display: 'inline' }}
                                      component="span"
                                      variant="body2"
                                      color="text.primary"
                                    >
                                      Última atualização: {moment(item.product_updated_at).format('DD/MM/YYYY')} - 
                                      {moment(item.product_updated_at).format('HH:mm')}
                                    </Typography>
                                    {` — ${item.product_created_at === item.product_updated_at || item.updated_by_user !== item.id ?
                                      'Criou' : 'Editou'} 
                                      o ${item.product_type === 'material' ? 'material' : 'serviço'}: 
                                      ${item.product_name}. Código: ${item.product_code}. `}
                                    <Typography
                                      sx={{ display: 'inline' }}
                                      component="span"
                                      variant="body2"
                                      color="text.primary"
                                    >
                                      Criado em: {moment(item.product_created_at).format('DD/MM/YYYY')} -
                                      {moment(item.product_created_at).format('HH:mm')}
                                    </Typography>
                                  </React.Fragment>
                                }
                              />
                            </ListItem>
                            <Divider variant="inset" component="li" />
                          </Box>
                        )}
                      </List>

                </Box>
              ) : null}
            </Box>
          ) : null}

        </Container>

      </CardContent>
    </Card>
  );
};

export default AccountProfile;
