import { useEffect, useState } from 'react';
import api from '../../services/api';

import { Box, Card, CardContent, CardHeader, Divider, Button, Menu, MenuItem } from '@mui/material';
import { Inventory, Support, Paid, ArrowDropDown } from '@mui/icons-material';

import { PolarArea } from 'react-chartjs-2';

export default function App({ profile, props, mobileView }) {

  useEffect(() => {

    getExpenseProducts();
    getSecretaries();

  }, []);

  const [anchorEl, setAnchorEl] = useState(null);
  let [expenseProducts, setExpenseProducts] = useState([]);
  let [secretaries, setSecretaries] = useState([]);
  let [secretary_id, setSecretary_id] = useState('');

  const handleMenu = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  async function getExpenseProducts() {

    try {
      let resp = await api.get('/expenseproducts', {
        params: {
          place: profile.place,
        }
      });
      if (resp != null) {
        if (resp.data) {
          if (JSON.stringify(resp.data).includes('name')) {

            await setExpenseProducts(resp.data);

          } else {
            //alert('Nenhuma secretaria encontrada. ' + resp.data);
          }
        }
      }
    } catch (error) {
      alert(error);
    }

  }

  async function getSecretaries() {

    try {
      let resp = await api.get('/secretaries', {
        params: {
          place: profile.place,
        }
      });
      if (resp != null) {
        if (resp.data) {
          if (JSON.stringify(resp.data).includes('name')) {

            if (secretaries.length > 0) {
              while (secretaries.length > 0) {
                secretaries.pop();
              }
            }

            for (let i = 0; i < resp.data.length; i++) {

              let aux = {
                id: resp.data[i].id,
                name: resp.data[i].name,
                place_name: resp.data[i].place_name,
                value: resp.data[i].value,
              };
              secretaries = Object.assign([], secretaries);
              secretaries.push(aux);
            }

            await setSecretaries(secretaries);

          } else {
            //alert('Nenhuma secretaria encontrada. ' + resp.data);
          }
        }
      }
    } catch (error) {
      alert(error);
    }

  }

  async function getSecretaryExpenseProducts(secretary_id) {

    try {
      let resp = await api.get('/secretaryexpenseproducts', {
        params: {
          place: profile.place,
          secretary_id: secretary_id,
        }
      });
      if (resp != null) {
        if (resp.data) {
          if (JSON.stringify(resp.data).includes('name')) {

            await setExpenseProducts(resp.data);

          } else {
            //alert('Nenhuma secretaria encontrada. ' + resp.data);
          }
        }
      }
    } catch (error) {
      alert(error);
    }

  }

  let data = {
    labels: expenseProducts.map((item) => item.name),
    datasets: [
      {
        label: 'Valores',
        data: expenseProducts.map((item) => item.value),
        backgroundColor: expenseProducts.map((item) => `rgba(${JSON.stringify(item.value).substring(0, 2)}5, 205, 50, 0.5)`),
        borderWidth: 1,
      },
    ],
  };

  return (
    <Card {...props} sx={{ backgroundColor: '#fff', width: mobileView ? '100%' : '30%' }}>
      <CardHeader
        action={(
          <Box>
            <Button
              endIcon={<ArrowDropDown fontSize="small" />}
              size="small"
              onClick={handleMenu}
            >
              Secretaria
            </Button>
            <Menu
              id="menu-appbar"
              anchorEl={anchorEl}
              anchorOrigin={{
                vertical: 'top',
                horizontal: 'right',
              }}
              keepMounted
              transformOrigin={{
                vertical: 'top',
                horizontal: 'right',
              }}
              open={Boolean(anchorEl)}
              onClose={handleClose}
            >
              {secretaries.map((item) => 
                <MenuItem
                  onClick={async () => { await setSecretary_id(item.id); getSecretaryExpenseProducts(item.id); handleClose(); }}>
                  {item.name}
                </MenuItem>
              )}
            </Menu>
          </Box>
        )}
        sx={{ color: '#252525' }}
        title={`Gastos por fonte de despesa${secretary_id !== '' ? ' - ' + secretaries.filter((item) => item.id === secretary_id)[0].name : ''}`}
      />
      <Divider />
      <CardContent>
        <Box
          sx={{
            height: '100%',
            position: 'relative'
          }}
        >
          <PolarArea data={data} />
        </Box>
      </CardContent>
    </Card>
  );
}
