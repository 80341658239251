import React, { Component } from 'react';
import api from '../services/api';
import apiString from '../services/apiString';
import apiCEP from '../services/apiCEP';

import styles from './styles/styles';

import Button from '@mui/material/Button';
import CssBaseline from '@mui/material/CssBaseline';
import TextField from '@mui/material/TextField';
import FormControlLabel from '@mui/material/FormControlLabel';
import Checkbox from '@mui/material/Checkbox';
import Link from '@mui/material/Link';
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
import Container from '@mui/material/Container';
import PersonAdd from '@mui/icons-material/PersonAdd';
import Alert from '@mui/material/Alert';
import IconButton from '@mui/material/IconButton';
import CloseIcon from '@mui/icons-material/Close';
import FormControl from '@mui/material/FormControl';
import FormLabel from '@mui/material/FormLabel';
import RadioGroup from '@mui/material/RadioGroup';
import Radio from '@mui/material/Radio';
import Assignment from '@mui/icons-material/Assignment';
import Avatar from '@mui/material/Avatar';
import InputLabel from '@mui/material/InputLabel';
import Select from '@mui/material/Select';
import ExitToAppIcon from '@mui/icons-material/ExitToApp';

import InputMask from 'react-input-mask';

import backgroundVideo from '../assets/videos/backgroundVideo.mp4';
import logo from '../assets/logo/logo_ksgc.png';
import Footer from '../components/Footer';

let emailValidator = require('react-email-validator');

export default class Register extends Component {

  state = {
    profile: [],

    admin_place: '',
    admin_mail: '',
    admin_password: '',

    name: '',
    email: '',
    option: 'admin',
    type: 'CPF',
    mask: '999.999.999-99',
    logged: false,
    typology: '',
    cep: '',
    cepMask: '99999-999',
    street: '',
    number: '',
    complement: '',
    district: '',
    city: '',
    secretary: '1',
    state: '',
    password: '',
    password2: '',
    phone: '',
    phoneMask: '(99) 99999-9999',

    place: '',
    places: [],
    secretaries: [],

    emailView: false,

    disabled: true,
    open: false,
    open_error: false,
    open_error2: false,
    msg: '',

    nameDisabled: false,
    cpf_cnpj: '',
    emailDisabled: false,
    passwordDisabled: false,
    avatar: '',
    editDisabled: false,
    secretaries: [],

    image: null,

    phoneMask: '(99) 99999-9999',

    disabled: true,
    open: false,
    open_error: false,
    open_error2: false,
    msg: '',
  }

  async componentDidMount() {

    if (this.props.location.state) {
      await this.setState({ profile: this.props.location.state.fields.profile });
    } 
    
    this.getTree();
    this.getPlaces();

  }

  getPlaces = async () => {
    let resp = await api.get('/places');
    if (resp != null) {
      if (resp.data) {
        if (JSON.stringify(resp.data).includes('name')) {
          this.setState({ places: resp.data });
        }
      }
    }
  }

  getTree = async () => {

    try {

      try {
        let resp = await api.get('/tree', {
          params: {
            type: 'Secretaria',
          }
        });
        if (resp != null) {
          if (resp.data) {
            if (JSON.stringify(resp.data).includes('name')) {

              await this.setState({ secretaries: [] });

              this.setState({ secretaries: resp.data });

            } else {
              //alert(resp.data);
            }
          }
        }
      } catch (err) {
        alert(err);
      }

    } catch (error) {
      alert(error);
    }

  }

  handleCEP = async () => {

    try{
      let resp = await apiCEP.get(`/ws/${this.state.cep}/json/`);
      if (resp != null) {
        if (resp.data) {
          if (JSON.stringify(resp.data).includes('cep')) {
            this.setState({ street: resp.data.logradouro });
            this.setState({ district: resp.data.bairro });
            this.setState({ city: resp.data.localidade });
            this.setState({ state: resp.data.uf });
            this.setState({ open_error2: false });
          } else {
            this.setState({ msg: 'CEP não encontrado.' });
            this.setState({ open_error2: true });
            this.setState({ open: false });
          }
        }
      }
    } catch(err) {
      this.setState({ street: '' });
      this.setState({ number: '' });
      this.setState({ district: '' });
      this.setState({ city: '' });
      this.setState({ state: '' });
      this.setState({ msg: 'CEP não encontrado.' });
      this.setState({ open_error2: true });
      this.setState({ open: false });
    }

  }

  handleClose = async () => {
    this.props.history.push({
      pathname: '/',
      state: {
        fields: {
          profile: this.state.profile
        }
      }
    });
  }

  logOut = async () => {
    await this.setState({ profile: [] });
    this.props.history.push({
      pathname: '/',
      state: {
        fields: {
          profile: this.state.profile
        }
      }
    });
  }

  handleNewImage = async e => {

    await e.preventDefault();

    await this.setState({ image: e.target.files[0] });
    const fileSize = this.state.image.size;

    if (fileSize > 3000000) {
      document.body.scrollTop = 0;
      document.documentElement.scrollTop = 0;
      this.setState({ msg: 'Tamanho máximo permitido: 3 MB' });
      this.setState({ open_error: true });
      this.setState({ open: false });
      this.setState({ image: null });
    } else {
      const objectUrl = URL.createObjectURL(this.state.image);
      await this.setState({ avatar: objectUrl });
    }

  }

  handleMask = async e => {
    await this.setState({ [e.target.name]: e.target.value });

    if (this.state.option === "cnpj") {
      this.setState({ mask: '99.999.999/9999-99' });
      this.setState({ type: 'CNPJ' });
      this.setState({ emailView: false });
    }
    if (this.state.option === "cpf" || this.state.option === 'admin' || this.state.option === 'servicer' || this.state.option === 'analyst') {
      this.setState({ mask: '999.999.999-99' });
      this.setState({ type: 'CPF' });
      this.setState({ emailView: false });
    }
  }

  handleAdminLogin = async () => {

    try {
      let resp = await api.get('/checkuser', {
        params: {
          email: this.state.admin_mail,
          password: this.state.admin_password,
          place: this.state.admin_place,
        }
      });
      if (resp != null) {
        if (resp.data) {
          if (JSON.stringify(resp.data).includes('name')) {
            let admin_profile = resp.data;
            if (admin_profile.type === 1) {
              this.setState({ logged: true });
              this.setState({ admin_mail: '' });
              this.setState({ admin_password: '' });
            } else {
              document.body.scrollTop = 0;
              document.documentElement.scrollTop = 0;
              this.setState({ msg: 'Usuário não autorizado' });
              this.setState({ open_error: true });
              this.setState({ open: false });
            }
          } else {
            let msg = JSON.stringify(resp.data);
            const finalmsg = msg.toString().split('"').join('');
            this.setState({ msg: finalmsg });
            this.setState({ open_error: true });
          }
        }
      }
    } catch (err) {
      console.error(err);
      this.setState({ msg: 'E-mail incorreto. ' + err });
      this.setState({ open_error: true });
    }

  }

  handleSubmit = async (e) => {
    e.preventDefault();

    const data = new FormData();

    if (!(30 >= this.state.password.length) || !(this.state.password.length >= 6)) {

      document.body.scrollTop = 0;
      document.documentElement.scrollTop = 0;
      this.setState({ msg: 'A senha precisa ter de 6 a 30 caracteres.' });
      this.setState({ open_error: true });
      this.setState({ open: false });

    } else {

      if (!emailValidator.validate(this.state.email)) {

        document.body.scrollTop = 0;
        document.documentElement.scrollTop = 0;
        this.setState({ msg: 'E-mail inválido.' });
        this.setState({ open_error: true });
        this.setState({ open: false });

      } else {

        if (this.state.image) {
          this.setState({ avatar: '' });
        }

        data.append('name', this.state.name);
        data.append('email', this.state.email);
        data.append('option', this.state.option);
        data.append('cpf_cnpj', this.state.cpf_cnpj);
        data.append('password', this.state.password);
        data.append('typology', this.state.typology);
        data.append('cep', this.state.cep);
        data.append('street', this.state.street);
        data.append('number', this.state.number);
        data.append('complement', this.state.complement);
        data.append('district', this.state.district);
        data.append('city', this.state.city);
        data.append('secretary', this.state.secretary);
        data.append('state', this.state.state);
        data.append('phone', this.state.phone);
        data.append('photo_url', this.state.avatar);
        if (this.state.image) {
          data.append('image', this.state.image);
        }
        data.append('place', this.state.place);

        try {
          let resp = await api.post('/user', data, {
            headers: {
              "Content-Type": "multipart/form-data",
            },
          });
          if (resp != null) {
            if (resp.data) {
              if (JSON.stringify(resp.data).includes('registred')) {

                document.body.scrollTop = 0;
                document.documentElement.scrollTop = 0;
                this.setState({ msg: 'Cadastro realizado com sucesso.' });
                this.setState({ open_error: false });
                this.setState({ open: true });

              } else {
                document.body.scrollTop = 0;
                document.documentElement.scrollTop = 0;
                this.setState({ msg: resp.data });
                this.setState({ open_error: true });
                this.setState({ open: false });
              }
            }
          }
        } catch (err) {
          document.body.scrollTop = 0;
          document.documentElement.scrollTop = 0;
          this.setState({ msg: 'Não foi possível incluir este usuário ' + err });
          this.setState({ open_error: true });
          this.setState({ open: false });
        }

      }

    }

  }

  handleChange = e => {
    this.setState({ [e.target.name]: e.target.value });
  }

  render() {
    return (

      <div style={styles.html}>
        <video autoPlay muted loop id="myVideo" style={styles.video}>
          <source src={backgroundVideo} type="video/mp4" />
        </video>
        <form style={styles.form} id="new_register" onSubmit={this.handleSubmit}>

          <Container component="main" maxWidth="xs">
            <CssBaseline />
            <div style={styles.paper}>
              <img src={logo} style={styles.avatar} alt="K-SGC" />
              <Typography component="h1" variant="h5">
                Cadastro
              </Typography>

              {this.state.open ? (
                <Alert
                  severity="success"
                  action={
                    <IconButton
                      aria-label="close"
                      color="inherit"
                      size="small"
                      onClick={() => {
                        this.handleClose();
                      }}
                    >
                      <CloseIcon fontSize="inherit" />
                    </IconButton>
                  }
                >
                  {this.state.msg}
                </Alert>
              ) : null}

              {this.state.open_error ? (
                <Alert
                  severity="error"
                  action={
                    <IconButton
                      aria-label="close"
                      color="inherit"
                      size="small"
                      onClick={() => {
                        this.setState({ open_error: false });
                      }}
                    >
                      <CloseIcon fontSize="inherit" />
                    </IconButton>
                  }
                >
                  {this.state.msg}
                </Alert>
              ) : null}

              {this.state.logged ? (
                <div style={styles.form}>
                  <Grid container spacing={2}>
                    <Typography variant="h6" style={{ marginLeft: 10 }}>
                      Informações principais
                    </Typography>
                    <Grid item xs={12}>
                      <input
                        accept="file_extension|image"
                        style={{ display: 'none' }}
                        id="button-attach"
                        multiple
                        type="file"
                        onChange={this.handleNewImage}
                      />
                      <label htmlFor="button-attach">
                        <Avatar alt="" src={this.state.avatar} sx={{ width: 96, height: 96 }} />
                      </label>
                    </Grid>
                    <Grid item xs={12}>
                      <TextField
                        autoComplete="fname"
                        name="name"
                        variant="standard"
                        required
                        fullWidth
                        id="name"
                        label="Nome"
                        disabled={this.state.nameDisabled}
                        placeholder='Seu nome e sobrenome'
                        InputProps={{ style: styles.input }}
                        onChange={this.handleChange}
                        value={this.state.name}
                      />
                    </Grid>
                    <Grid item xs={12}>
                      <TextField
                        variant="standard"
                        required
                        fullWidth
                        id="email"
                        label="E-mail"
                        disabled={this.state.emailDisabled}
                        name="email"
                        autoComplete="email"
                        InputProps={{ style: styles.input }}
                        onChange={this.handleChange}
                        value={this.state.email}
                      />
                    </Grid>
                    <Grid item xs={12}>
                      <TextField
                        variant="standard"
                        required
                        fullWidth
                        name="password"
                        label="Senha"
                        disabled={this.state.passwordDisabled}
                        type="password"
                        id="password"
                        autoComplete="current-password"
                        InputProps={{ style: styles.input }}
                        onChange={this.handleChange}
                        value={this.state.password}
                      />
                    </Grid>
                    <Grid item xs={12}>
                      <TextField
                        variant="standard"
                        required
                        fullWidth
                        name="password2"
                        label="Confirmação de senha"
                        disabled={this.state.passwordDisabled}
                        type="password"
                        id="password2"
                        autoComplete="current-password"
                        InputProps={{ style: styles.input }}
                        onChange={this.handleChange}
                        value={this.state.password2}
                      />
                    </Grid>

                    <Grid item xs={12}>
                      <FormControl component="fieldset">
                        <FormLabel component="legend">Opção</FormLabel>
                        <RadioGroup
                          aria-label="option1"
                          name="option"
                          value={this.state.option}
                          onChange={this.handleMask}
                          style={{ flexDirection: 'row' }}
                        >
                          <FormControlLabel value="admin" control={<Radio />} label="Administrador" />
                          <FormControlLabel value="cnpj" control={<Radio />} label="Órgão" />
                          <FormControlLabel value="cpf" control={<Radio />} label="Secretaria de Planejamento" />
                          <FormControlLabel value="analyst" control={<Radio />} label="Analista" />
                          <FormControlLabel value="servicer" control={<Radio />} label="Servidor" />
                        </RadioGroup>
                      </FormControl>

                      <InputMask
                        {...this.props.value}
                        mask={this.state.mask}
                        fullWidth
                        maskChar=" "
                        name="cpf_cnpj"
                        id="cpf_cnpj"
                        type="text"
                        onChange={this.handleChange}
                        value={this.state.cpf_cnpj}
                      >
                        {(inputProps) =>
                          <TextField
                            {...inputProps}
                            variant="outlined"
                            margin="normal"
                            label={this.state.type}
                            style={{ marginBottom: 15, marginTop: -2 }}
                          />
                        }
                      </InputMask>
                    </Grid>

                    <Grid item xs={12}>
                      <FormControl variant="outlined" fullWidth style={{ marginBottom: 15 }}>
                        <InputLabel htmlFor="outlined-place-native-simple">
                          Local *
                        </InputLabel>
                        <Select
                          native
                          required
                          value={this.state.place}
                          onChange={this.handleChange}
                          label="Local"
                          inputProps={{
                            name: 'place',
                            id: 'outlined-place-native-simple',
                          }}
                        >
                          {this.state.places.map((item) =>
                            <option value={`${item.id}`}>{item.name}</option>
                          )}
                        </Select>
                      </FormControl>
                    </Grid>

                    {this.state.option === 'cnpj' ? (

                      <Grid item xs={12}>

                        <Typography variant="h6" style={{ marginBottom: 10 }}>
                          Localização
                        </Typography>

                        {this.state.open_error2 ? (
                          <Alert
                            severity="error"
                            style={{ marginBottom: 5 }}
                            action={
                              <IconButton
                                aria-label="close"
                                color="inherit"
                                size="small"
                                onClick={() => {
                                  this.setState({ open_error2: false });
                                }}
                              >
                                <CloseIcon fontSize="inherit" />
                              </IconButton>
                            }
                          >
                            {this.state.msg}
                          </Alert>
                        ) : null}

                        <InputMask
                          {...this.props.value}
                          mask={this.state.cepMask}
                          fullWidth
                          maskChar=" "
                          name="cep"
                          id="cep"
                          type="text"
                          onChange={this.handleChange}
                          onBlur={this.handleCEP}
                          value={this.state.cep}
                        >
                          {(inputProps) =>
                            <TextField
                              {...inputProps}
                              variant="outlined"
                              margin="normal"
                              label="CEP"
                              style={{ marginBottom: 15, marginTop: -2 }}
                            />
                          }
                        </InputMask>

                        <div style={{ display: 'flex', flexDirection: 'row', marginBottom: 15 }}>
                          <TextField
                            name="street"
                            variant="outlined"
                            required
                            fullWidth
                            id="street"
                            label="Rua"
                            InputProps={{ style: styles.input }}
                            onChange={this.handleChange}
                            value={this.state.street}
                          />
                          <Typography component="h1" variant="h5" style={{ marginTop: 10 }}>
                            -
                          </Typography>
                          <TextField
                            name="number"
                            variant="outlined"
                            required
                            id="number"
                            label="Número"
                            InputProps={{ style: styles.input }}
                            onChange={this.handleChange}
                            value={this.state.number}
                            style={{ width: 150 }}
                          />
                        </div>

                        <TextField
                          name="complement"
                          variant="outlined"
                          fullWidth
                          id="complement"
                          label="Complemento"
                          InputProps={{ style: styles.input }}
                          onChange={this.handleChange}
                          value={this.state.complement}
                          style={{ marginBottom: 15 }}
                        />

                        <TextField
                          name="district"
                          variant="outlined"
                          required
                          fullWidth
                          id="district"
                          label="Bairro"
                          InputProps={{ style: styles.input }}
                          onChange={this.handleChange}
                          value={this.state.district}
                          style={{ marginBottom: 15 }}
                        />

                        <div style={{ display: 'flex', flexDirection: 'row' }}>
                          <TextField
                            name="city"
                            variant="outlined"
                            required
                            fullWidth
                            id="city"
                            label="Cidade"
                            InputProps={{ style: styles.input }}
                            onChange={this.handleChange}
                            value={this.state.city}
                          />
                          <Typography component="h1" variant="h5" style={{ marginTop: 10 }}>
                            -
                          </Typography>
                          <TextField
                            name="state"
                            variant="outlined"
                            required
                            id="state"
                            label="Estado"
                            InputProps={{ style: styles.input }}
                            onChange={this.handleChange}
                            value={this.state.state}
                            style={{ width: 150 }}
                          />
                        </div>
                      </Grid>

                    ) : this.state.option === 'analyst' || this.state.option === 'servicer' ? (
                      <Grid item xs={12}>
                        <FormControl variant="outlined" fullWidth style={{ marginBottom: 15 }}>
                          <InputLabel htmlFor="outlined-secretary-native-simple">
                            Secretaria *
                          </InputLabel>
                          <Select
                            native
                            required
                            value={this.state.secretary}
                            onChange={this.handleChange}
                            label="Secretaria"
                            inputProps={{
                              name: 'secretary',
                              id: 'outlined-secretary-native-simple',
                            }}
                          >
                            {this.state.secretaries.map((item) =>
                              <option value={`${item.id}`}>{item.parent_name ? item.parent_name + ' / ' : null}{item.name}</option>
                            )}
                          </Select>
                        </FormControl>
                      </Grid>
                    ) : null}

                    <Grid item xs={12} style={styles.directionRow}>
                      <Assignment style={{ margin: 4 }} />
                      <a href="http://kintelligence.com.br" target="_blank" rel="noreferrer">
                        <Typography variant="h6" color={'#38B6FF'}>
                          Termos e condições
                        </Typography>
                      </a>
                    </Grid>

                    <Grid item xs={12}>
                      <FormControlLabel onChange={() => this.setState({ disabled: !this.state.disabled })}
                        control={<Checkbox value="allowExtraEmails" color="primary" />}
                        label="Estou de acordo com os termos de uso desta aplicação."
                        aria-label={{ style: { color: '#000' } }}
                      />
                    </Grid>
                  </Grid>
                  <Button
                    type="submit"
                    fullWidth
                    variant="contained"
                    color="primary"
                    style={styles.submit}
                    disabled={this.state.disabled}
                    startIcon={<PersonAdd />}
                  >
                    Cadastrar
                  </Button>
                  <Grid container justifyContent="flex-end">
                    <Grid item>
                      <Link href={`${apiString}/`} variant="body2">
                        Já possui uma conta? Entre
                      </Link>
                    </Grid>
                  </Grid>
                </div>
              ) : (
                <div style={styles.paper}>
                  <Typography component="h1" variant="body1">
                    Login de Administrador
                  </Typography>

                  <div style={styles.form}>

                  <Grid item xs={12}>
                      <FormControl variant="outlined" fullWidth style={{ marginBottom: 15 }}>
                        <InputLabel htmlFor="outlined-admin_place-native-simple">
                          Local *
                        </InputLabel>
                        <Select
                          native
                          required
                          value={this.state.admin_place}
                          onChange={this.handleChange}
                          label="Local"
                          inputProps={{
                            name: 'admin_place',
                            id: 'outlined-admin_place-native-simple',
                          }}
                        >
                          {this.state.places.map((item) =>
                            <option value={`${item.id}`}>{item.name}</option>
                          )}
                        </Select>
                      </FormControl>
                    </Grid>

                    <TextField
                      variant="standard"
                      margin="normal"
                      fullWidth
                      required
                      id="admin_mail"
                      label="E-mail"
                      name="admin_mail"
                      autoComplete="admin_mail"
                      InputProps={{ style: styles.input }}
                      onChange={this.handleChange}
                      value={this.state.admin_mail}
                    />

                    <TextField
                      variant="standard"
                      margin="normal"
                      required
                      fullWidth
                      name="admin_password"
                      label="Senha"
                      type="password"
                      id="admin_password"
                      autoComplete="current-password"
                      InputProps={{ style: styles.input }}
                      onChange={this.handleChange}
                      value={this.state.admin_password}
                    />

                    <Button
                      type="button"
                      fullWidth
                      variant="contained"
                      color="primary"
                      style={styles.submit}
                      startIcon={<ExitToAppIcon />}
                      onClick={this.handleAdminLogin}
                    >
                      Entrar
                    </Button>
                  </div>
                </div>
              ) }

            </div>
          </Container>
          <Footer />
        </form>

      </div>
    );
  }

}