import { Component } from 'react';
import apiStringServer from '../services/apiStringServer';

import BottomNavigation from '@mui/material/BottomNavigation';
import BottomNavigationAction from '@mui/material/BottomNavigationAction';
import Avatar from '@mui/material/Avatar';
import DashboardIcon from '@mui/icons-material/Dashboard';
import TreeIcon from '@mui/icons-material/Schema';
import GridIcon from '@mui/icons-material/Grid3x3';

import Profile from './screens/Profile';
import Dashboard from './screens/Dashboard';
import Tree from './screens/Tree';
import Grid from './screens/Grid';

import styles from './styles/styles';

export default class LabelBottomNavigation extends Component {

  state = {
    profile: [],
    value: 'dashboard',

    profile_avatar: '',
  }

  async componentDidMount() {

    if (this.props.location.state) {
      await this.setState({ profile: this.props.location.state.fields.profile });
    } 

    this.setState({ profile_avatar: apiStringServer + '/images/' + this.state.profile.photo_url });


  }

  handleChange = (event, newValue) => {
    this.setState({ value: newValue });
  }

  render() {
    return (
      <html style={styles.html}>
        <BottomNavigation sx={{ width: '100%' }} value={this.state.value} onChange={(e, newValue) => this.handleChange(e, newValue)}>
          <BottomNavigationAction
            label="Painel de controle"
            name
            value="dashboard"
            icon={<DashboardIcon />}
          />
          <BottomNavigationAction
            label="Árvore"
            value="tree"
            icon={<TreeIcon />}
          />
          <BottomNavigationAction
            label="Tabela"
            value="grid"
            icon={<GridIcon />}
          />
          <BottomNavigationAction
            label="•"
            value="profile"
            icon={<Avatar
              alt=""
              src={this.state.profile_avatar}
              sx={{ width: 35, height: 35 }}
            />}
          />
        </BottomNavigation>
  
        {this.state.value === 'profile' ? (
          <Profile profile={this.state.profile} {...this.props} />
        ) : this.state.value === 'dashboard' ? (
          <Dashboard profile={this.state.profile} {...this.props} />
        ) : this.state.value === 'tree' ? (
          <Tree profile={this.state.profile} {...this.props} />
        ) : this.state.value === 'grid' ? (
          <Grid profile={this.state.profile} {...this.props} />
        ) : null}
      </html>
    );

  }

}
