import React, { Component } from 'react';
import api from '../services/api';
import apiString from '../services/apiString';

import Button from '@mui/material/Button';
import CssBaseline from '@mui/material/CssBaseline';
import TextField from '@mui/material/TextField';
import FormControlLabel from '@mui/material/FormControlLabel';
import Checkbox from '@mui/material/Checkbox';
import Link from '@mui/material/Link';
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
import Container from '@mui/material/Container';
import ExitToAppIcon from '@mui/icons-material/ExitToApp';
import Alert from '@mui/material/Alert';
import IconButton from '@mui/material/IconButton';
import CloseIcon from '@mui/icons-material/Close';
import { FormControl, InputLabel, Select } from '@mui/material';

import Footer from '../components/Footer';

import logo from '../assets/logo/logo_ksgc.png';

import backgroundVideo from '../assets/videos/backgroundVideo.mp4';

import styles from './styles/styles';

export default class Login extends Component {

  state = {
    email: '',
    password: '',

    profile: [],
    places: [],
    place: 'select',

    open_error: false,
    msg: '',
  }

  async componentDidMount() {

    if (this.props.location.state) {
      await this.setState({ profile: this.props.location.state.fields.profile });
    } 

    this.getPlaces();

  }

  getPlaces = async () => {
    let resp = await api.get('/places');
    if (resp != null) {
      if (resp.data) {
        if (JSON.stringify(resp.data).includes('name')) {
          this.setState({ places: resp.data });
        }
      }
    }
  }

  handleSubmit = async (e) => {
    e.preventDefault();
    try {
      let resp = await api.get('/checkuser', {
        params: {
          email: this.state.email,
          password: this.state.password,
          place: this.state.place,
        }
      });
      if (resp != null) {
        if (resp.data) {
          if (JSON.stringify(resp.data).includes('name')) {
            this.props.history.push({
              pathname: '/home',
              state: {
                fields: {
                  profile: resp.data,
                }
              }
            });
          } else {
            let msg = JSON.stringify(resp.data);
            const finalmsg = msg.toString().split('"').join('');
            this.setState({ msg: finalmsg });
            this.setState({ open_error: true });
          }
        }
      }
    } catch (err) {
      console.error(err);
      this.setState({ msg: 'E-mail incorreto. ' + err });
      this.setState({ open_error: true });
    }

  }

  handleChange = e => {
    this.setState({ [e.target.name]: e.target.value });
  }

  render() {

    return (

      <html style={styles.html}>
          <video autoPlay muted loop id="myVideo" style={styles.video}>
            <source src={backgroundVideo} type="video/mp4" />
          </video>
          <div style={styles.divForm}>
            <form style={styles.form} id="new_login" onSubmit={this.handleSubmit}>

              <Container component="main" maxWidth="xs">
                <CssBaseline />
                <div style={styles.paper}>
                  <img src={logo} style={styles.avatar} alt="K-SGC" />
                  <Typography component="h1" variant="h5" style={{ color: '#343434' }}>
                    Login
                  </Typography>

                  {this.state.open_error ? (
                    <Alert
                      severity="error"
                      action={
                        <IconButton
                          aria-label="close"
                          color="inherit"
                          size="small"
                          onClick={() => {
                            this.setState({ open_error: false });
                          }}
                        >
                          <CloseIcon fontSize="inherit" />
                        </IconButton>
                      }
                    >
                      {this.state.msg}
                    </Alert>
                  ) : null}

                  <div style={styles.form}>

                  <Grid item xs={12}>
                      <FormControl variant="outlined" fullWidth style={{ marginBottom: 15 }}>
                        <InputLabel htmlFor="outlined-place-native-simple">
                          Local *
                        </InputLabel>
                        <Select
                          native
                          required
                          value={this.state.place}
                          onChange={this.handleChange}
                          label="Local"
                          inputProps={{
                            name: 'place',
                            id: 'outlined-place-native-simple',
                          }}
                        >
                          <option value="select">Selecione um local</option>
                          {this.state.places.map((item) =>
                            <option value={`${item.id}`}>{item.name}</option>
                          )}
                        </Select>
                      </FormControl>
                    </Grid>

                    <TextField
                      variant="standard"
                      margin="normal"
                      fullWidth
                      required
                      id="email"
                      label="E-mail"
                      name="email"
                      autoComplete="email"
                      InputProps={{ style: styles.input }}
                      onChange={this.handleChange}
                      value={this.state.email}
                    />

                    <TextField
                      variant="standard"
                      margin="normal"
                      required
                      fullWidth
                      name="password"
                      label="Senha"
                      type="password"
                      id="password"
                      autoComplete="current-password"
                      InputProps={{ style: styles.input }}
                      onChange={this.handleChange}
                      value={this.state.password}
                    />
                    <FormControlLabel
                      control={<Checkbox value="remember" color="primary" />}
                      label="Lembrar de mim"
                    />
                    <Button
                      type="submit"
                      fullWidth
                      variant="contained"
                      color="primary"
                      style={styles.submit}
                      startIcon={<ExitToAppIcon />}
                    >
                      Entrar
                    </Button>
                    <Grid container>
                      <Grid item xs>
                        <Link href="#" variant="body2" style={styles.link}>
                          Esqueceu a senha?
                        </Link>
                      </Grid>
                    </Grid>
                  </div>
                </div>
              </Container>
              <Footer />
            </form>
          </div>
        </html>

    );

  }

}