import { useEffect, useState } from 'react';
import api from '../../services/api';

import { Doughnut } from 'react-chartjs-2';
import { Box, Card, CardContent, CardHeader, Divider, Typography, useTheme } from '@mui/material';
import LaptopMacIcon from '@mui/icons-material/Inventory';
import TabletIcon from '@mui/icons-material/Support';
import PhoneIcon from '@mui/icons-material/Paid';

const TrafficByDevice = ({ profile, props, mobileView }) => {

  useEffect(() => {

    getSecretaries();

  }, []);

  let [secretaries, setSecretaries] = useState([]);
  let [secretaryProducts, setSecretaryProducts] = useState([]);
  let [typeLength, setTypeLength] = useState([]);
  let [typeLength2, setTypeLength2] = useState([]);
  let [materialSpending, setMaterialSpending] = useState([]);
  let [serviceSpending, setServiceSpending] = useState([]);
  let [totalMaterial, setTotalMaterial] = useState(0);
  let [totalService, setTotalService] = useState(0);
  let [totalArray, setTotalArray] = useState([]);
  let [total, setTotal] = useState(0);

  async function getSecretaries() {

    try {
      let resp = await api.get('/secretarieswithproducts', {
        params: {
          place: profile.place,
        }
      });
      if (resp != null) {
        if (resp.data) {
          if (JSON.stringify(resp.data).includes('name')) {

            if (secretaries.length > 0) {
              while (secretaries.length > 0) {
                secretaries.pop();
              }
            }

            for (let i = 0; i < resp.data.length; i++) {

              let aux = {
                id: resp.data[i].id,
                name: resp.data[i].name,
                place_name: resp.data[i].place_name,
                value: resp.data[i].value,
              };
              secretaries = Object.assign([], secretaries);
              secretaries.push(aux);
            }

            await setSecretaries(secretaries);

            getProductsPerSecretary();

          } else {
            //alert('Nenhuma secretaria encontrada. ' + resp.data);
          }
        }
      }
    } catch (error) {
      alert(error);
    }

  }

  async function getProductsPerSecretary() {

    try {
      let resp = await api.get('/productspersecretary', {
        params: {
          place: profile.place,
        }
      });
      if (resp != null) {
        if (resp.data) {
          if (JSON.stringify(resp.data).includes('name')) {

            if (secretaryProducts.length > 0) {
              while (secretaryProducts.length > 0) {
                secretaryProducts.pop();
              }
            }

            if (typeLength.length > 0) {
              while (typeLength.length > 0) {
                typeLength.pop();
              }
            }

            if (typeLength2.length > 0) {
              while (typeLength2.length > 0) {
                typeLength2.pop();
              }
            }

            if (materialSpending.length > 0) {
              while (materialSpending.length > 0) {
                materialSpending.pop();
              }
            }

            if (serviceSpending.length > 0) {
              while (serviceSpending.length > 0) {
                serviceSpending.pop();
              }
            }

            for (let i = 0; i < resp.data.length; i++) {

              let aux = {
                id: resp.data[i].id,
                name: resp.data[i].name,
                count: resp.data[i].count,
                item_type: resp.data[i].item_type,
                product_value: resp.data[i].product_value,
              };
              secretaryProducts = Object.assign([], secretaryProducts);
              secretaryProducts.push(aux);
            }

            await setSecretaryProducts(secretaryProducts);

            for (let i = 0; i < secretaries.length; i++) {
              typeLength.push(0);
              typeLength2.push(0);
              materialSpending.push(0);
              serviceSpending.push(0);
            }

            for (let i = 0; i < secretaryProducts.length; i++) {
              for (let j = 0; j < secretaries.length; j++) {
                if (secretaryProducts[i].name === secretaries[j].name) {
                  if (secretaryProducts[i].item_type === 'material') {
                    typeLength[j] = typeLength[j] + secretaryProducts[i].count;
                    materialSpending[j] = parseFloat(materialSpending[j]) + parseFloat(secretaryProducts[i].product_value);
                  } else {
                    typeLength2[j] = typeLength2[j] + secretaryProducts[i].count;
                    serviceSpending[j] = parseFloat(serviceSpending[j]) + parseFloat(secretaryProducts[i].product_value);
                  }
                }
              }
            }

            setTypeLength(typeLength);
            setTypeLength2(typeLength2);
            await setMaterialSpending(materialSpending);
            await setServiceSpending(serviceSpending);

            for (let i = 0; i < materialSpending.length; i++) {
              totalMaterial = totalMaterial + parseFloat(materialSpending[i]);
            }
            setTotalMaterial(totalMaterial);

            for (let i = 0; i < serviceSpending.length; i++) {
              totalService = totalService + parseFloat(serviceSpending[i]);
            }
            setTotalService(totalService);

            totalArray = secretaries.map((item) => {
              if (item.value !== null) {
                return item.value;
              }
            });
            await setTotalArray(totalArray);
            for (let i = 0; i < totalArray.length; i++) {
              total = total + parseFloat(totalArray[i]);
            }
            setTotal(total);

          } else {
            //alert('Nenhuma quantidade de produtos encontrada. ' + resp.data);
          }
        }
      }
    } catch (error) {
      alert(error);
    }

  }

  const theme = useTheme();
  const labels = ['Material', 'Serviço', 'Total disponível'];

  const data = {
    datasets: [
      {
        data: [totalMaterial, totalService, total - (totalMaterial + totalService)], //dataLength,
        backgroundColor: ['#3F51B5', '#323232', '#32cd32'],
        borderWidth: 2,
        borderColor: '#FFFFFF',
        hoverBorderColor: '#FFFFFF'
      }
    ],
    labels: labels
  };

  const options = {
    animation: true,
    cutoutPercentage: 80,
    layout: { padding: 0 },
    legend: {
      display: false
    },
    maintainAspectRatio: false,
    responsive: true,
    tooltips: {
      backgroundColor: theme.palette.background.paper,
      bodyFontColor: theme.palette.text.secondary,
      borderColor: theme.palette.divider,
      borderWidth: 1,
      enabled: true,
      footerFontColor: theme.palette.text.secondary,
      intersect: false,
      mode: 'index',
      titleFontColor: theme.palette.text.primary
    }
  };

  const devices = [
    {
      title: 'Material',
      value: ((data.datasets[0].data[0] / (data.datasets[0].data[0] + data.datasets[0].data[1] + data.datasets[0].data[2])) * 100).toFixed(1),
      icon: () => <LaptopMacIcon color="secondary" />,
      color: '#3F51B5'
    },
    {
      title: 'Serviço',
      value: ((data.datasets[0].data[1] / (data.datasets[0].data[0] + data.datasets[0].data[1] + data.datasets[0].data[2])) * 100).toFixed(1),
      icon: () => <TabletIcon color="secondary" />,
      color: '#323232'
    },
    {
      title: 'Total disponível',
      value: ((data.datasets[0].data[2] / (data.datasets[0].data[0] + data.datasets[0].data[1] + data.datasets[0].data[2])) * 100).toFixed(1),
      icon: () => <PhoneIcon color="secondary" />,
      color: '#32cd32'
    }
  ];

  return (
    <Card {...props} sx={{ backgroundColor: '#fff', width: mobileView ? '100%' : '30%' }}>
      <CardHeader title="Gastos gerais" sx={{ color: '#252525' }} />
      <Divider />
      <CardContent>
        <Box
          sx={{
            height: 300,
            position: 'relative'
          }}
        >
          <Doughnut
            data={data}
            options={options}
          />
        </Box>
        <Box
          sx={{
            display: 'flex',
            justifyContent: 'center',
            pt: 2,
          }}
        >
          {devices.map(({
            color,
            icon: Icon,
            title,
            value
          }) => (
            <Box
              key={title}
              sx={{
                p: 1,
                textAlign: 'center',
              }}
            >
              <Icon color="primary" />
              <Typography
                color="#fff"
                variant="body1"
              >
                {title}
              </Typography>
              <Typography
                style={{ color }}
                variant="h4"
              >
                {value}
                %
              </Typography>
            </Box>
          ))}
        </Box>
      </CardContent>
    </Card>
  );
};

export default TrafficByDevice;