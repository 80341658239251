import { useEffect, useState } from 'react';
import api from '../../services/api';

import { Box, Card, CardContent, CardHeader, Divider, Typography, useTheme } from '@mui/material';
import { Inventory, Support, Paid } from '@mui/icons-material';

import { Radar } from 'react-chartjs-2';

export default function App({ profile, props, mobileView }) {
  
  useEffect(() => {

    getSecretaries();

  }, []);

  let [secretaries, setSecretaries] = useState([]);
  let [secretaryProducts, setSecretaryProducts] = useState([]);
  let [typeLength, setTypeLength] = useState([]);
  let [typeLength2, setTypeLength2] = useState([]);
  let [materialSpending, setMaterialSpending] = useState([]);
  let [serviceSpending, setServiceSpending] = useState([]);
  let [totalMaterial, setTotalMaterial] = useState(0);
  let [totalService, setTotalService] = useState(0);
  let [totalArray, setTotalArray] = useState([]);
  let [total, setTotal] = useState(0);

  async function getSecretaries() {

    try {
      let resp = await api.get('/secretarieswithproducts', {
        params: {
          place: profile.place,
        }
      });
      if (resp != null) {
        if (resp.data) {
          if (JSON.stringify(resp.data).includes('name')) {

            if (secretaries.length > 0) {
              while (secretaries.length > 0) {
                secretaries.pop();
              }
            }

            for (let i = 0; i < resp.data.length; i++) {

              let aux = {
                id: resp.data[i].id,
                name: resp.data[i].name,
                place_name: resp.data[i].place_name,
                value: resp.data[i].value,
              };
              secretaries = Object.assign([], secretaries);
              secretaries.push(aux);
            }

            await setSecretaries(secretaries);

            getProductsPerSecretary();

          } else {
            //alert('Nenhuma secretaria encontrada. ' + resp.data);
          }
        }
      }
    } catch (error) {
      alert(error);
    }

  }

  async function getProductsPerSecretary() {

    try {
      let resp = await api.get('/productspersecretary', {
        params: {
          place: profile.place,
        }
      });
      if (resp != null) {
        if (resp.data) {
          if (JSON.stringify(resp.data).includes('name')) {

            if (secretaryProducts.length > 0) {
              while (secretaryProducts.length > 0) {
                secretaryProducts.pop();
              }
            }

            if (typeLength.length > 0) {
              while (typeLength.length > 0) {
                typeLength.pop();
              }
            }

            if (typeLength2.length > 0) {
              while (typeLength2.length > 0) {
                typeLength2.pop();
              }
            }

            if (materialSpending.length > 0) {
              while (materialSpending.length > 0) {
                materialSpending.pop();
              }
            }

            if (serviceSpending.length > 0) {
              while (serviceSpending.length > 0) {
                serviceSpending.pop();
              }
            }

            for (let i = 0; i < resp.data.length; i++) {

              let aux = {
                id: resp.data[i].id,
                name: resp.data[i].name,
                count: resp.data[i].count,
                item_type: resp.data[i].item_type,
                product_value: resp.data[i].product_value,
              };
              secretaryProducts = Object.assign([], secretaryProducts);
              secretaryProducts.push(aux);
            }

            await setSecretaryProducts(secretaryProducts);

            for (let i = 0; i < secretaries.length; i++) {
              typeLength.push(0);
              typeLength2.push(0);
              materialSpending.push(0);
              serviceSpending.push(0);
            }

            for (let i = 0; i < secretaryProducts.length; i++) {
              for (let j = 0; j < secretaries.length; j++) {
                if (secretaryProducts[i].name === secretaries[j].name) {
                  if (secretaryProducts[i].item_type === 'material') {
                    typeLength[j] = typeLength[j] + secretaryProducts[i].count;
                    materialSpending[j] = parseFloat(materialSpending[j]) + parseFloat(secretaryProducts[i].product_value);
                  } else {
                    typeLength2[j] = typeLength2[j] + secretaryProducts[i].count;
                    serviceSpending[j] = parseFloat(serviceSpending[j]) + parseFloat(secretaryProducts[i].product_value);
                  }
                }
              }
            }

            setTypeLength(typeLength);
            setTypeLength2(typeLength2);
            await setMaterialSpending(materialSpending);
            await setServiceSpending(serviceSpending);

            for (let i = 0; i < materialSpending.length; i++) {
              totalMaterial = totalMaterial + parseFloat(materialSpending[i]);
            }
            setTotalMaterial(totalMaterial);

            for (let i = 0; i < serviceSpending.length; i++) {
              totalService = totalService + parseFloat(serviceSpending[i]);
            }
            setTotalService(totalService);

            totalArray = secretaries.map((item) => {
              if (item.value !== null) {
                return item.value;
              }
            });
            await setTotalArray(totalArray);
            for (let i = 0; i < totalArray.length; i++) {
              total = total + parseFloat(totalArray[i]);
            }
            setTotal(total);

          } else {
            //alert('Nenhuma quantidade de produtos encontrada. ' + resp.data);
          }
        }
      }
    } catch (error) {
      alert(error);
    }

  }

  const data = {
    labels: ['Material', 'Serviço', 'Total'],
    datasets: [
      {
        label: 'Gastos estimados',
        data: [totalMaterial, totalService, totalMaterial + totalService],
        backgroundColor: 'rgba(57,66,116, 0.2)',
        borderColor: 'rgba(57,66,116, 1)',
        borderWidth: 1,
      },
      {
        label: 'Orçamento anual',
        data: [total, total, total],
        backgroundColor: 'rgba(50, 205, 50, 0.2)',
        borderColor: 'rgba(50, 205, 50, 1)',
        borderWidth: 1,
      },
    ],
  };
  
  const devices = [
    {
      title: 'Gasto em material',
      value: ((data.datasets[0].data[0] / data.datasets[1].data[0]) * 100).toFixed(1),
      icon: () => <Inventory color="secondary" />,
      color: '#394274'
    },
    {
      title: 'Gasto em serviço',
      value: ((data.datasets[0].data[1] / data.datasets[1].data[1]) * 100).toFixed(1),
      icon: () => <Support color="secondary" />,
      color: '#394274'
    },
    {
      title: 'Gasto geral',
      value: ((data.datasets[0].data[2] / data.datasets[1].data[2]) * 100).toFixed(1),
      icon: () => <Paid color="secondary" />,
      color: '#394274'
    }
  ];

  return (
    <Card {...props} sx={{ backgroundColor: '#fff', width: mobileView ? '100%' : '30%' }}>
      <CardHeader title="Gastos gerais (comparativo)" sx={{ color: '#252525' }} />
      <Divider />
      <CardContent>
        <Box
          sx={{
            height: 200,
            position: 'relative'
          }}
        >
          <Radar data={data} />
        </Box>
        <Box
          sx={{
            display: 'flex',
            justifyContent: 'center',
            pt: 2,
            marginTop: 25
          }}
        >
          {devices.map(({
            color,
            icon: Icon,
            title,
            value
          }) => (
            <Box
              key={title}
              sx={{
                p: 1,
                textAlign: 'center',
              }}
            >
              <Icon color="primary" />
              <Typography
                color="#000"
                variant="body1"
              >
                {title}
              </Typography>
              <Typography
                style={{ color }}
                variant="h4"
              >
                {value}
                % <Typography variant="body1"> do total</Typography>
              </Typography>
            </Box>
          ))}
        </Box>
      </CardContent>
    </Card>
  );
}
